<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0 showFlexListDiv">
          <div class="iq-edit-list showFlexList">
            <ul class="iq-edit-profile nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
              <li class="flex-fill p-0">
                <a class="nav-link" :class="!openAbout && !openContact ? 'active' : ''" data-toggle="pill" href="#profile">
                  {{cvProfileInfo}}
                </a>
              </li>
              <li class="flex-fill p-0" >
                <a class="nav-link" :class="openAbout ? 'active' : ''" data-toggle="pill" href="#about">
                  {{cvAbout}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link"  :class="openContact ? 'active' : ''" data-toggle="pill" href="#contactsocial">{{cvContact}}</a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link" ref="edit-roles" data-toggle="pill" href="#edit-roles">
                  {{ cvEditRoles }}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link" data-toggle="pill" ref="manage-users" href="#manage-users">
                  {{ cvManageUsers }}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link" data-toggle="pill" href="#affiliation">
                  {{ cvAffiliation }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-edit-list-data">
        <div class="tab-content">
          <div class="tab-pane fade " :class="!openAbout && !openContact ? 'active show' : ''" id="profile" role="tabpanel">
            <iq-card class="mb-4 p-3">
              <div>
                <h4>
                  {{ cvOrgNameLabel }}
                </h4>
                <ValidationObserver ref="orgForm" >
                  <b-form>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider
                        name="Organisation Name"
                        rules="required"
                        v-slot="{ errors }">
                        <input v-model="vmOrganisationFormData.org_name" type="text" class="form-control" :placeholder="cvOrgNameLabel" required />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <br />
                      <div v-if="userData.user_role === 'USERROLE11111' || userData.user_role === 'USERROLE11118'">
                        <h4>
                          {{ cvOrgPriorityLabel }}
                        </h4>
                        <ValidationProvider
                          name="Organisation Priority"
                          v-slot="{ errors }">
                          <input v-model="vmOrganisationFormData.org_priority" type="text" class="form-control" :placeholder="cvOrgPriorityLabel" required />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <button type="button" class="d-flex btn-primary border-0 px-3 py-2 rounded mt-3" @click="organisationEdit(true)">
                        Save
                      </button>
                   </div>
                  </b-form>
                </ValidationObserver>
              </div>
              <div>
                <h4>
                  Profile Pic
                </h4>
                <hr>
                <b-form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border border-dark" style="height: 150px; width: 150px; border-radius: 50%; ">
                        <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden; border-radius: 50%; " />
                        <i v-else class="fas fa-orgersity d-flex justify-content-center align-items-center rounded-circle " style="font-size: 5rem; height:100%; "></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" style="cursor: pointer" ></i>
                          <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;" @change="openImageDialog($event, 'Profile Pic')" />
                        </label>
                      </div>
                    </div>
                    <span class="text-danger" ref="proPicError"></span>
                  </div>
                  <button type="button" class=" p-2 mr-3 mb-2 rounded btn btn-primary border-0 "  :disabled="cvProfilePicError || cvProfilePicLoading" @click="imageEdit('Profile Pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Save Profile Pic</span>
                  </button>
                  <button v-if="vmProfilePic" type="button" class="p-2 mb-2 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('profile_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Remove Profile Pic</span>
                  </button>
                </b-form>
              </div>
              <div class="mt-4">
                <h4>
                  Cover Pic
                </h4>
                <hr>
                <b-form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 250px; width: 100%;">
                        <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden;" />
                        <i v-else class="fa fa-university d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <div class="p-image">
                          <i class="ri-pencil-line upload-button"></i>
                          <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;" @change="openImageDialog($event, 'Cover Pic')" />
                        </div>
                      </div>
                    </div>
                    <span class="text-danger" ref="coverPicError"></span>
                  </div>
                  <button type="button" class="p-2 mr-3 rounded btn btn-primary border-0 " @click="imageEdit('Cover Pic')" >
                    <b-spinner small class="ml-4 mr-4" v-if="cvCoverPicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else> Save Cover Pic</span>
                  </button>
                  <button v-if="vmCoverPic" type="button" class="p-2 mt-2 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('cover_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Remove Cover Pic</span>
                  </button>
                </b-form>
              </div>
            </iq-card>
          </div>
          <div class="tab-pane fade" :class="openAbout ? 'active show' : ''" id="about" role="tabpanel">
            <iq-card class="mb-4 p-3">
              <div>
                <b-form>
                  <div>
                    <h4>
                      About Details
                    </h4>
                    <hr>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="validationorg_desc" >{{cvOrgDescLabel}}</label>
                        <vue-editor v-model="vmOrganisationFormData.org_desc">
                        </vue-editor>
                        <!-- <b-form-textarea v-model="vmOrganisationFormData.org_desc" style="line-height: 24px;" :placeholder="cvOrgDescLabel" rows="2"  max-rows="6"></b-form-textarea> -->
                      </div>
                    </div>
                    <div>
                      <h4>
                        Basic Info
                      </h4>
                      <hr>
                      <div class="col-md-12 mb-3">
                        <label for="validationorg_founding_year">{{cvOrgFoundingYearLabel}}</label>
                        <input v-model="vmOrganisationFormData.org_founding_year" type="number" class="form-control" />
                      </div>
                      <div class="col-md-12 mb-3">
                        <label for="validationorg_currency">{{cvOrgCurrencyLabel}}</label>
                        <multiselect v-model="vmOrganisationFormData.org_currency" :tag-placeholder="cvSelectLabel + cvOrgCurrencyLabel" :placeholder="cvSelectLabel + cvOrgCurrencyLabel" label="currency_name" track-by="currency_name" :searchable="true" :options="cvCurrencyOptions">
                        </multiselect>
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_intake_count" >{{cvOrgIntakeCountLabel}}</label>
                        <input v-model="vmOrganisationFormData.org_intake_count" type="number" class="form-control" />
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_deadline"> {{cvOrgDeadlineLabel}}</label>
                        <b-form-datepicker  v-model="vmOrganisationFormData.org_deadline" style="display:flex; align-items: center">
                        </b-form-datepicker>
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_admission_requirement" >Admission Requirement</label>
                        <vue-editor v-model="vmOrganisationFormData.org_admission_requirement">
                        </vue-editor>
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_top_alums" >Top Alums</label>
                        <vue-editor v-model="vmOrganisationFormData.org_top_alums">
                        </vue-editor>
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_avg_tuition_min">{{cvOrgAvgtuitionMinLabel}}</label>
                        <input v-model="vmOrganisationFormData.org_avg_tuition_min" type="text" class="form-control"/>
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_avg_tuition_max">{{cvOrgAvgtuitionMaxLabel}}</label>
                        <input v-model="vmOrganisationFormData.org_avg_tuition_max" type="text" class="form-control" />
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label  for="validationorg_scholarship_min"> {{cvOrgAvgScholarshipMinLabel}}</label>
                        <input v-model="vmOrganisationFormData.org_scholarship_min" type="text" class="form-control" />
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_scholarship_max">{{cvOrgAvgScholarshipMaxLabel}}</label >
                        <input v-model="vmOrganisationFormData.org_scholarship_max" type="text"  class="form-control" />
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_clubs" >Societies & clubs</label>
                        <vue-editor v-model="vmOrganisationFormData.org_clubs">
                        </vue-editor>
                        <!-- <b-form-textarea v-model="vmOrganisationFormData.org_clubs" style="line-height: 24px;" rows="2"  max-rows="6"></b-form-textarea> -->
                      </div>
                      <div class="col-md-12 mb-3" v-if="orgType == 'UNIV' ">
                        <label for="validationorg_major_industries_around">Major Industries Around</label>
                        <vue-editor v-model="vmOrganisationFormData.org_major_industries_around">
                        </vue-editor>
                        <!-- <b-form-textarea v-model="vmOrganisationFormData.org_major_industries_around" style="line-height: 24px;" rows="2"  max-rows="6"></b-form-textarea> -->
                      </div>
                    </div>
                  </div>
                  <button type="button" class="btn-primary border-0 px-3 py-2 rounded" @click="organisationEdit()" :disabled="cvLoadingStatus">
                    <b-spinner small class="ml-4 mr-4" v-if="cvLoadingStatus" label="Spinning"></b-spinner>
                    <span v-else>Save</span>
                  </button>
                </b-form>
              </div>
            </iq-card>
          </div>
          <div class="tab-pane fade" :class="openContact ? 'active show' : ''" id="contactsocial" role="tabpanel">
            <iq-card class="mb-4 p-3">
              <div>
                <b-form>
                  <div class="row">
                    <h4 class="pl-1">
                      Contact Information
                    </h4>
                    <hr>
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_email">{{cvOrgAdminEmail}}</label>
                      <input v-model="vmOrganisationFormData.org_admin_email" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_email">{{cvOrgEmailLabel}}</label>
                      <input v-model="vmOrganisationFormData.org_website_email" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3" v-if="orgType == 'UNIV'">
                      <label for="validationorg_email">{{cvOrgAdmisionEmailLabel}}</label>
                      <input v-model="vmOrganisationFormData.org_admision_email" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_mobile">{{cvOrgMobileLabel}}</label>
                      <input v-model="vmOrganisationFormData.org_mobile" type="text" class="form-control" />
                    </div>

                    <div class="col-md-12">
                      <label for="validationorg_city">{{cvOrgCityLabel}}</label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                      <input v-model="vmOrganisationFormData.org_city" type="text" class="form-control" placeholder="Enter the city" required />
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                      <multiselect v-model="vmOrganisationFormData.org_country" :tag-placeholder="cvSelectLabel + cvOrgCountryLabel" :placeholder="cvSelectLabel + cvOrgCountryLabel" label="country_name" track-by="country_name" :searchable="true"
                         :options="cvCountryOptions"></multiselect>
                    </div>

                    <div class="col-md-12" v-if="orgType == 'ORG'">
                      <!-- org_domain -->
                      <div class="col-md-12">
                        <label for="validationorg_city">{{cvOrgDomainLabel}}</label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                        <input v-model="vmOrganisationFormData.org_domain" type="text" class="form-control" placeholder="Enter the organisation's domain" required />
                      </div><!-- org_domain -->

                      <!-- org_uid -->
                      <div class="col-md-12" v-if="orgType == 'ORG'">
                        <label for="validationorg_city">
                          {{cvOrgUniqueIdLabel}}<br />
                        </label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                        <div class="d-flex align-items-center">
                          <input v-model="vmOrganisationFormData.org_uid" type="text" class="form-control" placeholder="Enter a 5 digit code for your organisation. e.g. AGCCD" required @input="setSaveBtnAbility" />
                          <button type="button" class="btn-primary border-0 rounded ml-4" @click="validateOrgIdentifier()">
                            Validate Identifier
                          </button>
                        </div>
                        <span><small>Note: The 5 Digit code is used to identify your organisation</small></span>
                      </div><!-- org_uid -->
                    </div>
                  </div>
                  <button type="button" class="p-2 mt-2 rounded btn btn-primary border-0 pointer" :disabled="isContactDetailsSaveBtnDisabled"  @click="organisationEdit()">
                    Save
                  </button>
                </b-form>
                <b-form class="mt-4">
                  <div>
                    <h4>
                      Website and Social Links
                    </h4>
                    <hr />
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_website" >{{cvOrgWebsiteLabel}}</label>
                      <input v-model="vmSocialLinkFormData.social_links.website" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_website" >Facebook</label>
                      <input v-model="vmSocialLinkFormData.social_links.facebook" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_website" >Twitter</label>
                      <input v-model="vmSocialLinkFormData.social_links.twitter" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_website" >Instagram</label>
                      <input v-model="vmSocialLinkFormData.social_links.instagram" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_website" >Youtube</label>
                      <input v-model="vmSocialLinkFormData.social_links.youtube" type="text" class="form-control" />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationorg_website" >Linkedin</label>
                      <input v-model="vmSocialLinkFormData.social_links.linkedin" type="text" class="form-control" />
                    </div>
                  </div>
                  <button type="button" class="btn-primary border-0 px-3 py-2 rounded" @click="socialLinksEdit()" :disabled="cvSocialLinkLoading">
                    <b-spinner small class="ml-4 mr-4" v-if="cvSocialLinkLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Save</span>
                  </button>
                </b-form>
              </div>
            </iq-card>
          </div>
          <div class="tab-pane fade" id="edit-roles" role="tabpanel">
            <iq-card class="pl-3">
              <div class="iq-card-header d-flex justify-content-between pl-0">
                <div class="iq-header-title">
                  <h4 class="card-title">
                    {{ cvEditRoles }}
                  </h4>
                </div>
              </div>
              <div class="iq-card-body p-0 pt-1">
                <RoleList v-if="orgType" :propModuleName="orgType" />
              </div>
            </iq-card>
          </div>
          <div class="tab-pane fade" id="manage-users" role="tabpanel">
            <iq-card class="pl-3">
              <div class="iq-card-header d-flex justify-content-between p-0">
                <div class="iq-header-title">
                  <h4 class="card-title">
                    {{ cvManageUsers }}
                  </h4>
                </div>
              </div>
              <div class="iq-card-body p-0 pt-2">
                <UserList v-if="orgType" :propModuleName="orgType"/>
              </div>
            </iq-card>
          </div>
          <div class="tab-pane fade" id="affiliation" role="tabpanel">
              <AffiliateList />
          </div>
        </div>
      </div>
    </div>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<style scoped lang="scss">
@media (max-width: 479px) {
  .profile-feed-items li{
    margin: 0px !important;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from "vue-multiselect"
import { ValidationProvider } from "vee-validate"
import { socialvue } from "../../../config/pluginInit"
import { Organisations } from "../../../FackApi/api/organisation"
import { Countries } from "../../../FackApi/api/country"
import { Images } from "../../../FackApi/api/image"
import { SocialLinks } from "../../../FackApi/api/socialLink"
import ApiResponse from "../../../Utils/apiResponse"
import RoleList from "../Role/RoleList.vue"
import UserList from "../../User/UserList.vue"
import moment from "moment"
import CropImage from "../../../components/cropImage.vue"
import { VueEditor } from "vue2-editor"
import AffiliateList from "../../Gide/Affiliate/AffiliateList.vue"
import userPermission from "../../../Utils/user_permission"
import { User } from "../../../FackApi/api/user"
import { OrgSaas } from "../../../FackApi/api/orgSaas"

export default {
  name: "ProfileEdit",
  components: {
    Multiselect,
    RoleList,
    UserList,
    ValidationProvider,
    CropImage,
    VueEditor,
    AffiliateList
  },
  data () {
    return {
      apiName: "organisation_edit",
      cvLoadingStatus: false,
      orgType: null,
      cvSelectLabel: "Select ",
      cvProfileInfo: "Profile",
      cvAbout: "About",
      cvContact: "Contact",
      cvEditRoles: "Manage Roles",
      cvManageUsers: "Manage Users",
      cvAffiliation: "Affiliate Codes",
      cvCardTitle: "Edit Organisation",
      cvCardSubHeader: "Edit Organisation ",
      cvSubmitBtn: "Edit",
      cvOrgNameLabel: "Organisation Name",
      cvOrgPriorityLabel: "Display Priority",
      cvOrgDescLabel: "Description",
      cvOrgEmailLabel: "Support/Query/Website Email",
      cvOrgAdmisionEmailLabel: "Admission Email",
      cvOrgAdminEmail: "Primary Email",
      cvOrgMobileLabel: "Phone",
      cvOrgCollegeBasisAppLabel: "College Basis App",
      cvOrgCityLabel: "Address",
      cvOrgDomainLabel: "Domain",
      cvOrgUniqueIdLabel: "Organisation Identifier",
      cvOrgCountryLabel: "Country",
      cvOrgWebsiteLabel: "Website",
      cvOrgFoundingYearLabel: "Founding Year",
      cvOrgDeadlineLabel: "Deadline",
      cvOrgIntakeCountLabel: "Intake Count",
      cvOrgIntakeTypeLabel: "Intake Type",
      cvOrgCurrencyLabel: "Currency",
      cvOrgAvgtuitionMinLabel: "Avg tuition Min",
      cvOrgAvgtuitionMaxLabel: "Avg tuition Max",
      cvOrgAvgScholarshipMinLabel: "Avg Scholarship Min",
      cvOrgAvgScholarshipMaxLabel: "Avg Scholarship Max",
      cvOrgPhone: "Phone",
      cvOrgEmail: "Email",
      cvOrgProfilePic: "Profile Pic",
      cvOrgCoverPic: "Cover Pic",
      showToast: false,
      showModelAskOtpBox: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation",
      cvSocialLinkLoading: false,
      cvProfilePicError: false,
      cvCoverPicError: false,
      cvProfilePicLoading: false,
      cvCoverPicLoading: false,
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 4 mb.",
      vmOrganisationFormData: {},
      vmOrganisationProPicImage: Object.assign({}, this.initImageData()),
      vmOrganisationCoverPicImage: Object.assign({}, this.initImageData()),
      vmProfilePic: "",
      vmCoverPic: "",
      vmOrgProfileImage: Object.assign({}, this.initImageData()),
      vmSocialLinkFormData: Object.assign({}, this.initSocialLinks()),
      cvCountryOptions: [],
      cvCurrencyOptions: [],
      cvCountrySelectValue: {},
      cvDelProfilePicLoading: false,
      cvDelCoverPicLoading: false,
      orgObj: {},
      openAbout: !!(this.$route.query && this.$route.query.about),
      openContact: !!(this.$route.query && this.$route.query.contact),
      profilePicObj: {},
      coverPicObj: {},
      propCropImageDialogOpen: false,
      fileSrc: "",
      updateImageType: null,
      propStencilSize: null,
      propCropDialogSize: "md",
      cvOrgId: null,
      orgUid: null,
      isContactDetailsSaveBtnDisabled: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }

    if (this.$route.params.org_type == "ORG") {
      this.isContactDetailsSaveBtnDisabled = true
    }

    // The admin needs to access this page in order to create or edit organisations
    if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
      return
    }

    this.orgType = this.$route.params.org_type
    this.cvOrgId = this.$route.params.org_id

    if (!(this.userData?.modules?.organisations &&
      this.userData?.modules?.organisations && this.userData.modules.organisations[this.cvOrgId] &&
      this.userData.modules.organisations[this.cvOrgId].org_id == this.cvOrgId)
    ) {
      // Not an owner of this organisation. Unauth Access. So Send back to home
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    if (this.$route.hash && this.$route.hash.length > 0) {
      console.log("XXX", this.$refs[this.$route.hash.substring(1)])
      this.$refs[this.$route.hash.substring(1)].click()
    }

    this.orgType = this.$route.params.org_type
    await this.loadRequiredData()

    /* If the 'user_first_time_login' flag is , the user has successflly landed the edit page
    set the flag to 0 in the database */
    if ((this.userData.user_first_time_login == 1 || this.userData.user_first_time_login == "1") && this.userData.user_role == "USERROLE11116") {
      const userEditResp = await User.userEdit(this, { user_id: this.userData.user_id, user_first_time_login: 0 })
      ApiResponse(this, userEditResp)
    }
  },
  methods: {
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.$route.params.org_id,
        module_name: this.orgType,
        image: [],
        image_type: {}
      }
    },
    /** */
    initSocialLinks () {
      return {
        module_id: this.$route.params.org_id,
        module_name: this.orgType,
        social_links: {
          website: "",
          facebook: "",
          twitter: "",
          instagram: "",
          youtube: "",
          linkedin: ""
        }
      }
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("YYYY-MM-DD")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      socialvue.index()
      await this.organisationView()
      this.imageGetProfileImages()
      this.assignOrgRoleToTheUser()
      this.socialLinksView()
      this.countryDistinctList()
      this.currencyDistinctList()
    },
    /**
     * organisationView
     */
    async organisationView () {
      let orgId = this.$route.params.org_id
      try {
        let orgListResp = await Organisations.organisationView(this, orgId)
        if (orgListResp && !orgListResp.resp_status) {
          return false
        }
        else {
          this.vmOrganisationFormData = orgListResp.resp_data.data
          // set organisation deadline
          if (orgListResp.resp_data.data.org_deadline) {
            this.vmOrganisationFormData.org_deadline = new Date(orgListResp.resp_data.data.org_deadline)
          }

          // set organisation country
          if (orgListResp.resp_data.data.country_id && orgListResp.resp_data.data.country_name) {
            this.vmOrganisationFormData.org_country = {
              id: orgListResp.resp_data.data.country_id,
              country_name: orgListResp.resp_data.data.country_name
            }
          }

          this.orgUid = orgListResp.resp_data.data.org_uid
          if (this.vmOrganisationFormData.org_uid) {
            this.isContactDetailsSaveBtnDisabled = false
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationView() and Exception:", err.message)
      }
    },
    /**
     * assignOrgRoleToTheUser
     */
    async assignOrgRoleToTheUser () {
      let userData = JSON.parse(JSON.stringify(this.userData))
      if (!userData.modules) {
        userData.modules = {}
      }

      if (!userData.modules.organisations) {
        userData.modules.organisations = {}
      }

      if (!userData.roles) {
        userData.roles = {}
      }

      userData.roles[this.orgObj["org_id"]] = {
        "actions": [],
        "mod_obj_id": this.orgObj["org_id"],
        "udr_name": "Admin"
      }

      userData.modules.organisations[this.orgObj["org_id"]] = this.orgObj
      userData.modules.organisations[this.orgObj["org_id"]]["profile_pic"] = this.profilePicObj
      userData.modules.organisations[this.orgObj["org_id"]]["cover_pic"] = this.coverPicObj
      await this.$store.dispatch("User/setUserProfileAction", JSON.parse(JSON.stringify(userData)))
    },
    /**
     * addOrgToModules
     */
    async addOrgToModules () {
      let userData = JSON.parse(JSON.stringify(this.userData))
      if (!userData.modules) {
        userData.modules = {}
      }
      if (!userData.modules.organisations) {
        userData.modules.organisations = {}
      }
      userData.modules.organisations[this.orgObj["org_id"]] = this.orgObj
      userData.modules.organisations[this.orgObj["org_id"]]["profile_pic"] = this.profilePicObj
      userData.modules.organisations[this.orgObj["org_id"]]["cover_pic"] = this.coverPicObj
      await this.$store.dispatch("User/setUserProfileAction", JSON.parse(JSON.stringify(userData)))
    },
    /**
     * organisationEdit
     */
    async organisationEdit (saveName) {
      try {
        this.cvLoadingStatus = true
        let formData = {}
        if (saveName) {
          formData = { org_id: this.vmOrganisationFormData.org_id, org_name: this.vmOrganisationFormData.org_name, org_priority: this.vmOrganisationFormData.org_priority, org_module_obj_id: this.vmOrganisationFormData.org_id }
        }
        else {
          formData = {
            org_id: this.vmOrganisationFormData.org_id,
            org_desc: this.vmOrganisationFormData.org_desc,
            org_founding_year: this.vmOrganisationFormData.org_founding_year,
            org_currency: this.vmOrganisationFormData.org_currency,
            org_priority: this.vmOrganisationFormData.org_priority,
            org_website_email: this.vmOrganisationFormData.org_website_email,
            org_mobile: this.vmOrganisationFormData.org_mobile,
            org_city: this.vmOrganisationFormData.org_city,
            org_country: this.vmOrganisationFormData.org_country,
            org_intake_count: this.vmOrganisationFormData.org_intake_count,
            org_deadline: this.vmOrganisationFormData.org_deadline,
            org_avg_tuition_max: this.vmOrganisationFormData.org_avg_tuition_max,
            org_avg_tuition_min: this.vmOrganisationFormData.org_avg_tuition_min,
            org_scholarship_max: this.vmOrganisationFormData.org_scholarship_max,
            org_scholarship_min: this.vmOrganisationFormData.org_scholarship_min,
            org_admission_requirement: this.vmOrganisationFormData.org_admission_requirement,
            org_top_alums: this.vmOrganisationFormData.org_top_alums,
            org_major_industries_around: this.vmOrganisationFormData.org_major_industries_around,
            org_clubs: this.vmOrganisationFormData.org_clubs,
            org_module_obj_id: this.vmOrganisationFormData.org_id,
            org_admin_email: this.vmOrganisationFormData.org_admin_email,
            org_admision_email: this.vmOrganisationFormData.org_admision_email,
            org_domain: this.vmOrganisationFormData.org_domain,
            org_uid: this.vmOrganisationFormData.org_uid
          }

          // validate org_domain
          if (formData.org_domain) {
            const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/
            if (!domainRegex.test(formData.org_domain)) {
              this.toastMsg = "Invalid domain name"
              this.toastVariant = "danger"
              this.showToast = true
              return
            }
          }
        }

        let organisationAddResp = await Organisations.organisationEdit(this, formData)
        await ApiResponse.responseMessageDisplay(this, organisationAddResp)
        if (organisationAddResp && !organisationAddResp.resp_status) {
          return false
        }
        else {
          if (this.$route.query && this.$route.query.about) {
            await this.$router.push("/organisation_edit/" + this.$route.params.org_type + "/" + this.$route.params.org_id + "?contact=true")
            this.openAbout = false
            this.openContact = true
          }
        }
        await this.addOrgToModules()
      }
      catch (err) {
        console.error("Exception occurred at organisationEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * setSaveBtnAbility
     */
    setSaveBtnAbility () {
      if (this.vmOrganisationFormData.org_uid != this.orgUid) {
        this.isContactDetailsSaveBtnDisabled = true
      }
      else {
        this.isContactDetailsSaveBtnDisabled = false
      }
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "Profile Pic" && this.vmOrganisationProPicImage.image.length === 0) {
        return false
      }

      if (imageType === "Cover Pic" && this.vmOrganisationCoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = true
          image = this.vmOrganisationProPicImage
        }
        else {
          this.cvCoverPicLoading = true
          image = this.vmOrganisationCoverPicImage
        }

        const imageUpdateResp = await Images.imageProfileUpdate(this, image)

        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        let imageResp = imageUpdateResp.resp_data
        if (imageResp && imageResp.length > 0) {
          if (imageType === "Profile Pic") {
            this.profilePicObj = imageResp[0]
          }
          else {
            this.coverPicObj = imageResp[0]
          }
          await this.addOrgToModules()
        }
        this.vmOrganisationProPicImage = Object.assign({}, this.initImageData())
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = false
        }
        else {
          this.cvCoverPicLoading = false
        }
      }
    },
    /**
     * imageRemove
     */
    async imageRemove (imgType) {
      if (imgType === "profile_pic") {
        this.cvDelProfilePicLoading = true
      }
      else {
        this.cvDelCoverPicLoading = true
      }

      let img = imgType === "profile_pic" ? 2 : 3
      const imageUpdateResp = await Images.imageDelete(this, this.$route.params.org_id, img)
      await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
      if (imageUpdateResp && !imageUpdateResp.resp_status) {
        return false
      }

      if (imgType === "profile_pic") {
        this.vmProfilePic = ""
        this.cvDelProfilePicLoading = false
      }
      else {
        this.vmCoverPic = ""
        this.cvDelCoverPicLoading = false
      }
    },
    /**
     * validateOrgIdentifier
     */
    async validateOrgIdentifier () {
      try {
        if (!this.vmOrganisationFormData.org_uid) {
          this.toastMsg = "Please enter a valid organisation identifier"
          this.toastVariant = "danger"
          this.showToast = true
        }
        const validateOrgIdentifierResp = await OrgSaas.orgsaasValidateOrgIdentifier(this, { org_uid: this.vmOrganisationFormData.org_uid })
        if (validateOrgIdentifierResp.resp_status) {
          this.isContactDetailsSaveBtnDisabled = false
        }
        ApiResponse.responseMessageDisplay(this, validateOrgIdentifierResp)
      }
      catch (err) {
        console.error("Exception in validateOrgIdentifier and err: ", err)
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }

      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        this.toastVariant = "danger"
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }

        this.toastVariant = ""
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * imageGetProfileImages()
     */
    async imageGetProfileImages () {
      try {
        let orgId = this.$route.params.org_id
        let imageProfileResp = await Images.imageListProfileImages(this, orgId)
        if (imageProfileResp && !imageProfileResp.resp_status) {
          return false
        }
        else {
          this.profilePicObj = imageProfileResp.resp_data.profile_pic
          this.coverPicObj = imageProfileResp.resp_data.cover_pic
          this.vmProfilePic = imageProfileResp.resp_data.profile_pic ? imageProfileResp.resp_data.profile_pic.image_thumbnail : ""
          this.vmCoverPic = imageProfileResp.resp_data.cover_pic ? imageProfileResp.resp_data.cover_pic.image : ""
        }
      }
      catch (err) {
        console.error("Exception occurred at imageGetProfileImages() and Exception:", err.message)
      }
    },
    /**
     * socialLinksView()
     */
    async socialLinksView () {
      try {
        let orgId = this.$route.params.org_id
        let socialLinkViewResp = await SocialLinks.socialLinkView(this, orgId)
        if (socialLinkViewResp && !socialLinkViewResp.resp_status) {
          return false
        }
        else {
          this.vmSocialLinkFormData.social_links = JSON.parse(socialLinkViewResp.resp_data.data.social_links)
        }
      }
      catch (err) {
        console.error("Exception occurred at socialLinksView() and exception", err.message)
      }
    },
    /**
     * socialLinksAdd
     */
    async socialLinksEdit () {
      if (Object.values(this.vmSocialLinkFormData.social_links).filter(Boolean).length < 1) {
        return
      }
      try {
        this.cvSocialLinkLoading = true
        this.vmSocialLinkFormData.module_id = this.$route.params.org_id
        const socialLinksEditResp = await SocialLinks.socialLinkProfileUpdate(this, this.vmSocialLinkFormData)
        await ApiResponse.responseMessageDisplay(this, socialLinksEditResp)
        if (socialLinksEditResp && !socialLinksEditResp.resp_status) {
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at socialLinksEdit() and exception", err.message)
      }
      finally {
        this.cvSocialLinkLoading = false
      }
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }

        this.cvCountryOptions = countryDistinctListResp.resp_data.data
        let countryIndex = this.cvCountryOptions.findIndex(country => country.country_name === this.vmOrganisationFormData.org_country)
        if (countryIndex >= 0) {
          this.vmOrganisationFormData.org_country = this.cvCountryOptions[countryIndex]
        }
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * currencyDistinctList
     */
    async currencyDistinctList () {
      try {
        let matchWithQuery = "distinct currency"
        let currencyDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (currencyDistinctListResp && !currencyDistinctListResp.resp_status) {
          return false
        }
        this.cvCurrencyOptions = currencyDistinctListResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at currencyDistinctList() and Exception:", err.message)
      }
    },
    /**
    * onChangeProfileImage
    */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmOrganisationProPicImage = Object.assign({}, this.initImageData())
        this.vmOrganisationProPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmOrganisationProPicImage.image_type[orgImg.name] = 2
      }
      else {
        this.vmOrganisationCoverPicImage = Object.assign({}, this.initImageData())
        this.vmOrganisationCoverPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmOrganisationCoverPicImage.image_type[orgImg.name] = 3
      }
    },
    /**
     * restoreLocation
     */
    restoreLocation (currentLoaction) {
      try {
        if (currentLoaction === "Profile") {
          this.openProfile = true
        }
      }
      catch (err) {
        console.error("Exception occurred at restoreLocation() and Exception:", err.message)
      }
    }
  }
}
</script>
